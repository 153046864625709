import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import SplitText from "gsap/SplitText";
import Splide from "@splidejs/splide";
import LazyLoad from "vanilla-lazyload";

// --------------------------------------------------
// 🐌 General
// --------------------------------------------------

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);

gsap.defaults({
  ease: "power1.inOut",
  duration: 0.5,
});

// --------------------------------------------------
// 💤 Lazy Loading
// --------------------------------------------------

const lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
  elements_selector: "[data-lazy]",
  callback_loaded: (el) => {
    gsap.to(el, {
      autoAlpha: 1,
      duration: 0.5,
      ease: "power1.inOut",
      onComplete: () => {
        el.dispatchEvent(new Event("lazyloaded"));
      },
    });
  },
});

// --------------------------------------------------
// 🎠 Carousels
// --------------------------------------------------

let bannerCarousel = document.querySelector(".splide--banner");

if (bannerCarousel) {
  let bannerSplide = new Splide(bannerCarousel, {
    type: "fade",
    rewind: true,
    speed: 1200,
    pauseOnHover: false,
    interval: 3400,
    autoplay: true,
    arrows: false,
    pagination: false,
    perPage: 1,
    perMove: 1,
    focus: "center",
    width: "100%",
    updateOnMove: true,
  });

  // Trigger lazyloading after splide has cloned the slides
  // bannerSplide.on('ready', () => {
  //   setupLazyLoading(bannerCarousel);
  // })

  // let firstImage = document.querySelector('.home-gallery__image');

  // setupLazyLoading(data.next.container);

  // firstImage.addEventListener('lazyloaded', () => {
  //   setupCarousel(data.next.container);
  // })

  bannerSplide.mount();
}

// --------------------------------------------------
// Slide in menu on mobile
// --------------------------------------------------

let menuButton = document.getElementById("menu-button");
let menuModal = document.getElementById("menu-modal");
let closeButton = document.getElementById("menu-modal-close");

menuButton.addEventListener("click", () => {
  console.log("clicked");
  menuModal.classList.add("active");
  document.body.style.overflow = "hidden";
});

closeButton.addEventListener("click", () => {
  menuModal.classList.remove("active");
  document.body.style.overflow = "auto";
});

// --------------------------------------------------
// ScrollSmoother
// --------------------------------------------------

// create the scrollSmoother before your scrollTriggers
let smoother = ScrollSmoother.create({
  smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
  effects: true, // looks for data-speed and data-lag attributes on elements
  smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
  // wholePixels: true,
});

// ignore Mobile Resize
ScrollTrigger.config({ ignoreMobileResize: true });

// --------------------------------------------------
// Scroll to
// --------------------------------------------------
// to view navigate to -  https://cdpn.io/pen/debug/XWVvMGr#section3

// links on desktop menu
gsap.utils.toArray(".scroll-to").forEach(function (link) {
  link.addEventListener("click", (e) => {
    var id = link.getAttribute("href");
    console.log(id);
    smoother.scrollTo(id, true, "top top");
    e.preventDefault();
  });
});

// links on desktop menu
gsap.utils.toArray(".scroll-to-mobile").forEach(function (link) {
  link.addEventListener("click", (e) => {
    if (menuModal.classList.contains("active")) {
      menuModal.classList.remove("active");
      document.body.style.overflow = "auto";
    }
    var id = link.getAttribute("href");
    console.log(id);
    smoother.scrollTo(id, true, "top top");
    e.preventDefault();
  });
});

// external links to site
window.onload = (event) => {
  console.log("page is fully loaded");

  let urlHash = window.location.href.split("#")[1];

  let scrollElem = document.querySelector("#" + urlHash);

  console.log(scrollElem, urlHash);

  if (urlHash && scrollElem) {
    gsap.to(smoother, {
      scrollTop: smoother.offset(scrollElem, "top 10%"),
      duration: 1,
      delay: 0.5,
    });
  }
};

// --------------------------------------------------
// Navbar color
// --------------------------------------------------

const responsiveColor = document.querySelectorAll(".responsive-color");
if (responsiveColor) {
  const allDarks = document.querySelectorAll(".dark-background");

  // Create change effect for each portfolio navigation item

  allDarks.forEach((darkElement) => {
    ScrollTrigger.create({
      trigger: darkElement,
      start: "top -5%",
      end: "bottom bottom",
      toggleClass: {
        targets: ".responsive-color",
        className: "responsive-color--light",
      },
      // markers: true
    });
  });
}

// --------------------------------------------------
// Intro animation
// --------------------------------------------------

let mm = gsap.matchMedia();

let introTl = gsap.timeline({
  scrollTrigger: {
    trigger: ".intro",
    pin: true,
    pinSpacing: false, // Disable automatic adjustment of spacing when pinning
    markers: false,
    scrub: 2,
    start: "top top",
    // end: "+=500%", // End pinning after scrolling 200% past the trigger
    endTrigger: ".team",
    end: "top top", // End pinning after scrolling 200% past the trigger
  },
});

introTl.to(".white-rectangle", {
  height: "100vh",
  width: "100vw",
  duration: 3,
  ease: "power2.inOut",
});

introTl.to(
  ".scroll-down",
  {
    autoAlpha: 0,
    duration: 1,
    ease: "power2.inOut",
  },
  "-=2.5",
);

introTl.to(
  ".intro__text--1",
  {
    opacity: 1,
    duration: 1.25,
    ease: "power2.inOut",
  },
  "-=2.75",
);

gsap.set(document.querySelector(".navbar__list"), {
  opacity: 1,
});

introTl.to(
  ".intro__text--1",
  {
    opacity: 0,
    duration: 0.25,
    ease: "power2.inOut",
  },
  "-=1.75",
);

introTl.to(
  ".header__logo",
  {
    opacity: 1,
    duration: 0.5,
    ease: "power2.inOut",
  },
  "-=1.5",
);

mm.add("(min-width: 851px)", () => {
  introTl.to(
    ".header",
    {
      top: "8rem",
      duration: 1,
      ease: "power2.inOut",
    },
    "-=1.25",
  );
});

// Iterate over each navbar__item and create SplitText instance for its text
document.querySelectorAll(".navbar__item").forEach((item, index) => {
  const itemText = new SplitText(item, { type: "chars" });

  // Set initial opacity of each character to 0
  itemText.chars.forEach((char) => {
    gsap.set(char, { opacity: 0 });
  });

  // Create a timeline for each navbar__item
  const itemTimeline = gsap.timeline({
    delay: index * 0.2, // Adjust the delay to stagger the start of each navbar__item
  });

  // Add animations to fade in the characters of each navbar__item
  itemTimeline.to(itemText.chars, {
    opacity: 1,
    duration: 0.5,
    stagger: 0.1, // Adjust the stagger value based on your preference
    ease: "power2.inOut",
  });

  // // Add each item timeline to the main timeline
  introTl.add(itemTimeline, "<"); // Set the position to 0 to overlap the timeline
});

mm.add("(max-width: 850px)", () => {
  introTl.to(
    ".header",
    {
      top: "3.5rem",
      duration: 1,
      ease: "power2.inOut",
    },
    "-=1.25",
  );
});

// mm.add("(max-width: 850px)", () => {
//   introTl.to(
//     ".header",
//     {
//       autoAlpha: 0,
//       duration: 1.5,
//       ease: "power2.inOut",
//     },
//     "-=1.25",
//   );
// });

mm.add("(max-width: 850px)", () => {
  introTl.to(".intro__text--2 p", {
    opacity: 1,
    duration: 0,
    ease: "power2.inOut",
  });
  introTl.to(".intro__text--2 p em:first-of-type", {
    color: "black",
    duration: 0.5,
    ease: "power2.inOut",
  });
  introTl.to(
    ".intro__text--2 p em:last-of-type",
    {
      color: "black",
      duration: 0.5,
      ease: "power2.inOut",
    },
    "-=.25",
  );

  introTl.to(
    ".intro__text--2 p em:nth-of-type(3)",
    {
      color: "black",
      duration: 0.5,
      ease: "power2.inOut",
    },
    "-=.25",
  );

  introTl.to(
    ".intro__text--2 p em:nth-of-type(2)",
    {
      color: "black",
      duration: 0.5,
      ease: "power2.inOut",
    },
    "-=.25",
  );

  introTl.to({}, { duration: 0.25 });

  introTl.to(".intro__text--2 p", {
    color: "black",
    duration: 0.5,
    ease: "power2.inOut",
  });

  introTl.to({}, { duration: 1.5 });

  introTl.to(
    ".intro__text--2",
    {
      opacity: 0,
      duration: 0.5,
      ease: "power2.inOut",
    },
    "-=.75",
  );

  introTl.to(
    ".intro",
    {
      opacity: 0,
      duration: 0.5,
    },
    "-=.5",
  );
});

mm.add("(min-width: 851px)", () => {
  introTl.to(
    ".intro__text--2 p",
    {
      opacity: 1,
      duration: 0,
      ease: "power2.inOut",
    },
    "-=3",
  );
  introTl.to(
    ".intro__text--2 p em:first-of-type",
    {
      color: "black",
      duration: 0.5,
      ease: "power2.inOut",
    },
    "-=1.5",
  );
  introTl.to(
    ".intro__text--2 p em:last-of-type",
    {
      color: "black",
      duration: 0.5,
      ease: "power2.inOut",
    },
    "-=.25",
  );

  introTl.to(
    ".intro__text--2 p em:nth-of-type(3)",
    {
      color: "black",
      duration: 0.5,
      ease: "power2.inOut",
    },
    "-=.25",
  );

  introTl.to(
    ".intro__text--2 p em:nth-of-type(2)",
    {
      color: "black",
      duration: 0.5,
      ease: "power2.inOut",
    },
    "-=.25",
  );

  introTl.to({}, { duration: 0.25 });

  introTl.to(".intro__text--2 p", {
    color: "black",
    duration: 0.5,
    ease: "power2.inOut",
  });

  introTl.to({}, { duration: 1.5 });

  introTl.to(
    ".intro__text--2",
    {
      opacity: 0,
      duration: 0.5,
      ease: "power2.inOut",
    },
    "-=.75",
  );

  introTl.to(
    ".intro",
    {
      opacity: 0,
      duration: 0.5,
    },
    "-=.5",
  );
});

// --------------------------------------------------
// Team animation
// --------------------------------------------------

// Create a GSAP timeline animation
var teamTl = gsap.timeline({
  // Add any necessary options for your timeline
});

mm.add("(min-width: 851px)", () => {
  // Create a ScrollTrigger to trigger the animation
  ScrollTrigger.create({
    trigger: ".team__text-content",
    start: "top top", // Start pinning when the top of the trigger reaches the top of the viewport
    pin: true, // Enable pinning
    pinSpacing: false, // Disable automatic adjustment of spacing when pinning
    scrub: 1,
    animation: teamTl,
    end: "+=260%", // End pinning after scrolling 200% past the trigger
  });
});

mm.add("(max-width: 850px)", () => {
  // Create a ScrollTrigger to trigger the animation
  ScrollTrigger.create({
    trigger: ".team__text-content",
    start: "top top", // Start pinning when the top of the trigger reaches the top of the viewport
    pin: true, // Enable pinning
    pinSpacing: false, // Disable automatic adjustment of spacing when pinning
    scrub: 1,
    animation: teamTl,
    end: "+=170%", // End pinning after scrolling 200% past the trigger
  });
});

gsap.set(document.querySelectorAll(".team__text"), {
  y: "150%", // Start from below .team__texts
  opacity: 0,
});

teamTl.to('.team__text[data-index="0"]', {
  y: 0,
  duration: 0.5,
  opacity: 1,
  ease: "power2.inOut",
});

teamTl.to(
  '.team__text[data-index="0"]',
  {
    y: "-150%",
    opacity: 0,
    duration: 0.5,
    ease: "power2.inOut",
  },
  "+=1",
);

teamTl.to(
  '.team__text[data-index="1"]',
  {
    y: 0,
    opacity: 1,
    duration: 0.5,
    ease: "power2.inOut",
  },
  "-=.5",
);

teamTl.to(
  '.team__text[data-index="1"]',
  {
    y: "-150%",
    opacity: 0,
    duration: 0.5,
    ease: "power2.inOut",
  },
  "+=1",
);

teamTl.to(
  '.team__text[data-index="2"]',
  {
    y: 0,
    opacity: 1,
    duration: 0.5,
    ease: "power2.inOut",
  },
  "-=.5",
);

teamTl.to(
  '.team__text[data-index="2"]',
  {
    y: "-150%",
    opacity: 0,
    duration: 0.5,
    ease: "power2.inOut",
  },
  "+=1",
);

teamTl.to(
  '.team__text[data-index="3"]',
  {
    y: 0,
    opacity: 1,
    duration: 0.5,
    ease: "power2.inOut",
  },
  "-=.5",
);

teamTl.to(
  '.team__text[data-index="3"]',
  {
    y: "-150%",
    opacity: 0,
    duration: 0.5,
    ease: "power2.inOut",
  },
  "+=1",
);

mm.add("(min-width: 851px)", () => {
  teamTl.to({}, { duration: 1 });
});

// --------------------------------------------------
// Services animation
// --------------------------------------------------
// Create a GSAP timeline animation
var servicesTl = gsap.timeline({
  // Add any necessary options for your timeline
});

// Create a ScrollTrigger to trigger the animation
ScrollTrigger.create({
  trigger: ".services__text-content",
  start: "top 50%", // Start pinning when the top of the trigger reaches the top of the viewport
  scrub: 1,
  animation: servicesTl,
  end: "+=250%", // End pinning after scrolling 200% past the trigger
});

// Create a ScrollTrigger to pin the element after the animation
ScrollTrigger.create({
  trigger: ".services__text-content",
  start: "top top", // Start pinning when the top of the trigger reaches the top of the viewport
  pin: true, // Enable pinning
  pinSpacing: false, // Disable automatic adjustment of spacing when pinning
  // markers: true,
  end: "+=250%", // End pinning after scrolling 200% past the trigger
});

// let servicesTl = gsap.timeline({
//   scrollTrigger: {
//     trigger: ".services__heading",
//     start: "top 30%", // Start pinning when the top of the trigger reaches the top of the viewport
//     pin: true, // Enable pinning
//     pinSpacing: false, // Disable automatic adjustment of spacing when pinning
//     // markers: true,
//     scrub: 1,
//     // endTrigger: ".services-wrapper",
//     end: "+=250%", // End pinning after scrolling 200% past the trigger
//   },
// });

gsap.set(document.querySelectorAll(".service"), {
  y: "25%", // Start from below
});

mm.add("(min-width: 851px)", () => {
  servicesTl.to(".services__second-sticky .sticky-lines", {
    autoAlpha: 1,
    duration: 0.5,
    ease: "power2.inOut",
  });

  servicesTl.to(
    '.service[data-index="0"]',
    {
      y: 0,
      duration: 0.5,
      autoAlpha: 1,
      ease: "power2.inOut",
    },
    "+=.5",
  );
});

mm.add("(max-width: 850px)", () => {
  servicesTl.to(".services__second-sticky .sticky-lines", {
    autoAlpha: 1,
    duration: 0.5,
    ease: "power2.inOut",
  });

  servicesTl.to('.service[data-index="0"]', {
    y: 0,
    duration: 0.5,
    autoAlpha: 1,
    ease: "power2.inOut",
  });
});

servicesTl.to(
  ".services-wrapper",
  {
    duration: 0.5,
    backgroundColor: "#2656D8",
    ease: "power2.inOut",
  },
  "-=.5",
);

servicesTl.to(
  '.service[data-index="0"] .service__paragraph',
  {
    duration: 1,
    autoAlpha: 1,
    ease: "power2.inOut",
  },
  "-=.25",
);

servicesTl.to({}, { duration: 0.5 });

servicesTl.to(
  '.service[data-index="0"]',
  {
    autoAlpha: 0,
    duration: 0.5,
    ease: "power2.inOut",
  },
  "+=.5",
);

servicesTl.to('.service[data-index="1"]', {
  y: 0,
  autoAlpha: 1,
  duration: 0.5,
  ease: "power2.inOut",
});

servicesTl.to(
  ".services-wrapper",
  {
    duration: 0.5,
    backgroundColor: "#53A6BC",
    ease: "power2.inOut",
  },
  "-=.5",
);

servicesTl.to(
  '.service[data-index="1"] .service__paragraph',
  {
    duration: 1,
    autoAlpha: 1,
    ease: "power2.inOut",
  },
  "-=.25",
);

servicesTl.to({}, { duration: 0.5 });

servicesTl.to('.service[data-index="1"]', {
  autoAlpha: 0,
  duration: 0.5,
  ease: "power2.inOut",
});

servicesTl.to('.service[data-index="2"]', {
  y: 0,
  autoAlpha: 1,
  duration: 0.5,
  ease: "power2.inOut",
});

servicesTl.to(
  ".services-wrapper",
  {
    duration: 0.5,
    backgroundColor: "#D23400",
    ease: "power2.inOut",
  },
  "-=.5",
);

servicesTl.to(
  '.service[data-index="2"] .service__paragraph',
  {
    duration: 1,
    autoAlpha: 1,
    ease: "power2.inOut",
  },
  "-=.25",
);

servicesTl.to({}, { duration: 0.5 });

servicesTl.to('.service[data-index="2"]', {
  autoAlpha: 0,
  duration: 0.5,
  ease: "power2.inOut",
});

servicesTl.to(".services__second-sticky .sticky-lines", {
  autoAlpha: 0,
  duration: 0.5,
  ease: "power2.inOut",
});

servicesTl.to({}, { duration: 1 });

// let serviceList = document.querySelector(".services__content");
// if (serviceList) {
//   const services = document.querySelectorAll(".service");
//   const servicesWrapper = document.querySelector(".services-wrapper");

//   services.forEach((service) => {
//     const color = service.dataset.color;

//     ScrollTrigger.create({
//       trigger: service,
//       start: "top 50%",
//       end: (self) => "+=" + service.offsetHeight,
//       onToggle: (self) => {
//         if (self.isActive) {
//           gsap.to(servicesWrapper, {
//             backgroundColor: color || "transparent",
//             duration: 0.6,
//             overwrite: "auto",
//           });
//         }
//       },
//     });
//   });
// }

// --------------------------------------------------
// Clients animation
// --------------------------------------------------

// Create a GSAP timeline animation
var clientsTl = gsap.timeline({
  // Add any necessary options for your timeline
});

// Create a ScrollTrigger to trigger the animation
ScrollTrigger.create({
  trigger: ".clients__text-content",
  start: "top 50%", // Start pinning when the top of the trigger reaches the top of the viewport
  scrub: 1,
  animation: clientsTl,
  endTrigger: ".clients__content",
  end: "bottom center",
});

// Create a ScrollTrigger to pin the element after the animation
ScrollTrigger.create({
  trigger: ".clients__text-content",
  start: "top top", // Start pinning when the top of the trigger reaches the top of the viewport
  pin: true, // Enable pinning
  pinSpacing: false, // Disable automatic adjustment of spacing when pinning
  // markers: true,
  endTrigger: ".clients__content",
  end: "bottom center",
});

// let clientsTl = gsap.timeline({
//   scrollTrigger: {
//     trigger: ".clients__text-content",
//     start: "top top", // Start pinning when the top of the trigger reaches the top of the viewport
//     pin: true, // Enable pinning
//     pinSpacing: false, // Disable automatic adjustment of spacing when pinning
//     // markers: true,
//     scrub: 1,
//     endTrigger: ".clients__content",
//     end: "bottom center",
//   },
// });

clientsTl.to(".clients__second-sticky .sticky-lines", {
  autoAlpha: 1,
  duration: 0.5,
  ease: "power2.inOut",
});

clientsTl.to({}, { duration: 1 });

clientsTl.to(".clients__second-sticky .sticky-lines", {
  autoAlpha: 0,
  duration: 0.5,
  ease: "power2.inOut",
});

let clientList = document.querySelector(".clients__content");
if (clientList) {
  const allClients = clientList.querySelectorAll(".client");

  // Create change effect for each portfolio navigation item

  allClients.forEach((client) => {
    const clientIndex = client.getAttribute("data-image");
    const correspondingImage = document.querySelector(
      `.client-image__wrapper[data-image="${clientIndex}"]`,
    );

    // Check if the user is using iOS
    function isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }

    // Check if the user is using Android
    function isAndroid() {
      return /Android/.test(navigator.userAgent);
    }

    // Check if the user is using iOS, Android, or neither

    if (isIOS()) {
      ScrollTrigger.create({
        trigger: client,
        start: "top 58%",
        end: "bottom 58%",
        // markers: true,
        // onRefresh: ScrollTrigger.refresh(),
        onToggle: (self) => {
          if (self.isActive) {
            client.classList.add("client--in-view");
            gsap.set(correspondingImage, {
              autoAlpha: 1,
            });
            correspondingImage.style.pointerEvents = "auto";
          } else {
            client.classList.remove("client--in-view");
            gsap.set(correspondingImage, {
              autoAlpha: 0,
            });
            correspondingImage.style.pointerEvents = "none";
          }
        },
      });
    } else if (isAndroid()) {
      ScrollTrigger.create({
        trigger: client,
        start: "top 53%",
        end: "bottom 53%",
        // markers: true,
        // onRefresh: ScrollTrigger.refresh(),
        onToggle: (self) => {
          if (self.isActive) {
            client.classList.add("client--in-view");
            gsap.set(correspondingImage, {
              autoAlpha: 1,
            });
            correspondingImage.style.pointerEvents = "auto";
          } else {
            client.classList.remove("client--in-view");
            gsap.set(correspondingImage, {
              autoAlpha: 0,
            });
            correspondingImage.style.pointerEvents = "none";
          }
        },
      });
    } else {
      ScrollTrigger.create({
        trigger: client,
        start: "top center",
        end: "bottom center",
        // markers: true,
        // onRefresh: ScrollTrigger.refresh(),
        onToggle: (self) => {
          if (self.isActive) {
            client.classList.add("client--in-view");
            gsap.set(correspondingImage, {
              autoAlpha: 1,
            });
            correspondingImage.style.pointerEvents = "auto";
          } else {
            client.classList.remove("client--in-view");
            gsap.set(correspondingImage, {
              autoAlpha: 0,
              delay: 0.05,
            });
            correspondingImage.style.pointerEvents = "none";
          }
        },
      });
    }
  });
}

// function callAfterResize(func, delay) {
//   let dc = gsap.delayedCall(delay || 0.2, func).pause(),
//     handler = () => dc.restart(true);
//   window.addEventListener("resize", handler);
//   return handler; // in case you want to window.removeEventListener() later
// }
// callAfterResize(ScrollTrigger.refresh());

// let previousHeight = window.innerHeight;

// function checkAddressBar() {
//   const currentHeight = window.innerHeight;

//   if (currentHeight !== previousHeight) {
//     // Height has changed, refresh ScrollTrigger
//     ScrollTrigger.refresh();
//     console.log("Height changed. Refreshing ScrollTrigger...");
//     previousHeight = currentHeight; // Update previousHeight
//     console.log(currentHeight);
//   }
// }

// // Initial check
// checkAddressBar();

// // Listen for scroll events
// window.addEventListener("scroll", checkAddressBar);

// --------------------------------------------------
// contact animation
// --------------------------------------------------

let contactTl = gsap.timeline({
  scrollTrigger: {
    trigger: ".contact__text-content",
    start: "top top", // Start pinning when the top of the trigger reaches the top of the viewport
    pin: true, // Enable pinning
    pinSpacing: false, // Disable automatic adjustment of spacing when pinning
    // markers: true,
    scrub: 1,
    endTrigger: ".contact-wrapper",
    end: "bottom bottom",
  },
});

let contactAnimationTl = gsap.timeline({
  scrollTrigger: {
    trigger: ".contact__content",
    start: "top 40%",
    end: "bottom 90%",
    // markers: true,
    // scrub: 1,
  },
});

mm.add("(min-width: 851px)", () => {
  contactAnimationTl.to(".contact__animation--instagram", {
    left: "25%",
    duration: 1.5,
    // ease: "back.out(4)",
    ease: "power2.inOut",
  });

  contactAnimationTl.to(
    ".contact__animation--linkedin",
    {
      left: "30%",
      duration: 1.5,
      // ease: "back.out(4)",
      ease: "power2.inOut",
    },
    "-=1.5",
  );

  contactAnimationTl.to(
    ".contact__animation--contact",
    {
      left: "70%",
      duration: 1.5,
      // ease: "back.out(4)",
      ease: "power2.inOut",
    },
    "-=1.5",
  );

  contactAnimationTl.to(
    ".contact__animation--email",
    {
      left: "75%",
      duration: 1.5,
      // ease: "back.out(4)",
      ease: "power2.inOut",
    },
    "-=1.5",
  );
});

contactAnimationTl.from(".v-logo__rectangle", {
  duration: 1,
  scaleX: 0,
  transformOrigin: "0% 100%",
  ease: "power4",
});

// Set things up
gsap.set(document.querySelector(".v-logo__v"), {
  opacity: 0,
});

contactAnimationTl.to(
  ".v-logo__v",
  {
    opacity: 1,
    duration: 0.5,
    ease: "power4",
  },
  "-=.5",
);

// --------------------------------------------------
// Reveal text scrolltrigger
// --------------------------------------------------

const texts = gsap.utils.toArray(document.querySelectorAll(".reveal"));

gsap.set(texts, { autoAlpha: 0 });

texts.forEach((text, i) => {
  const anim = gsap.to(text, {
    autoAlpha: 1,
    duration: 0.6,
    stagger: 0.0,
    ease: "power1.inOut",
    paused: true,
    delay: 0.3,
  });

  mm.add("(min-width: 800px)", () => {
    // Use callbacks to control the state of the animation
    ScrollTrigger.create({
      trigger: text,
      start: "top 90%",
      once: false,
      onEnter: (self) => {
        // If it's scrolled past, set the state
        // If it's scrolled to, play it
        anim.play();
      },
    });
  });

  mm.add("(max-width: 799px)", () => {
    // Use callbacks to control the state of the animation
    ScrollTrigger.create({
      trigger: text,
      start: "top 95%",
      once: false,
      onEnter: (self) => {
        // If it's scrolled past, set the state
        // If it's scrolled to, play it
        anim.play();
      },
    });
  });

  ScrollTrigger.create({
    trigger: text,
    start: "top bottom",
    onLeaveBack: () => anim.pause(0),
  });
});

// --------------------------------------------------
// 🎥 Serve correct video size relative to screen size
// --------------------------------------------------

// function updateVideoSources() {
//   heroVideos.forEach(multiSourceVideoElement => {
//       const sources = multiSourceVideoElement.getElementsByTagName("source");
//       const screenWidth = window.innerWidth;

//       let selectedSource = getSourceByResolution(sources, "original"); // Default to original if no other source matches

//       if (screenWidth > 2000) {
//           selectedSource = getSourceByResolution(sources, "1080");
//       } else if (screenWidth > 540) {
//           selectedSource = getSourceByResolution(sources, "720");
//       } else if (screenWidth > 400) {
//           selectedSource = getSourceByResolution(sources, "540");
//       } else {
//           selectedSource = getSourceByResolution(sources, "360");
//       }

//       multiSourceVideoElement.setAttribute("data-src", selectedSource.getAttribute("data-src"));
//       multiSourceVideoElement.load();
//   });
// }

// function getSourceByResolution(sources, resolution) {
//   for (const source of sources) {
//     if (source.getAttribute("data-resolution") === resolution) {
//         return source;
//     }
//   }

//   // If the exact resolution isn't found, find the next numerically higher resolution source
//   let nextResolution = "original";
//   for (const source of sources) {
//     const sourceResolution = source.getAttribute("data-resolution");
//     if (!isNaN(sourceResolution) && sourceResolution > resolution && (nextResolution === "original" || sourceResolution < nextResolution)) {
//         nextResolution = sourceResolution;
//     }
//   }

//   for (const source of sources) {
//     if (source.getAttribute("data-resolution") === nextResolution) {
//         return source;
//     }
//   }

//   return null;
// }

// let heroVideos = document.querySelectorAll('.hero__image video');
// if (heroVideos) {
//   // Initial update based on viewport width
//   updateVideoSources();
// }
